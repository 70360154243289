/* Fonts| */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* ---Poppins */
.poppins-light {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
}
/* ----Dm serif */
.dm-serif-display-regular {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-display-regular-italic {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: italic;
}
/* Utilitiy classes */

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.d-flex {
  display: flex;
}

.flex-grid .flex-grid-child {
  padding: 0 0.75rem;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.status {
  display: inline !important;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: #868eae10;
  padding: 0.2813rem 0.7406rem;
  border-radius: 0.9375rem;
}

.status.Success {
  background-color: #20c99710;
  color: #20c997;
}

.status.warning {
  background-color: #fa8b0c10;
  color: #fa8b0c;
}

.status.error {
  background-color: #ff4d4f10;
  color: #ff4d4f;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}
.mb-25 {
  margin-bottom: 1.5625rem !important;
}
.mb-30 {
  margin-bottom: 1.875rem;
}

.pb-30 {
  padding-bottom: 1.875rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-25 {
  margin-top: 1.5625rem;
}
.mt-30 {
  margin-top: 1.875rem;
}

.pr-0 {
  padding-right: 0 !important;
}

/* default card style */

.ant-card {
  box-shadow: 0rem 0.125rem 0.375rem #0000000a;
  border-radius: 0.75rem !important;
}

.ant-card-body {
  padding: 1.5625rem !important;
}

.ant-card-head {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;
}

/* .ant-card-head-title span {
  display: inline-block;
  margin-left: .9375rem;
  font-size: .75rem;
  font-weight: 500;
  color: #868eae;
} */

.ant-card-head .ant-card-head-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #000;
  font-size: 1rem;
  padding: 1.125rem 0;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 1.25rem;
}

/* ---- */

/* .custom-card-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.custom-card-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
} */

.card-border {
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 1rem;
}

.card-bordered {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-divider {
  height: 20px;
  /* padding-top: 10px; */
  /* margin: 5px 16px; */
  margin-top: 5px;
}

.card-data {
  display: flex;
  flex-direction: column;
}

.card-name {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 300;
  /* margin-bottom: 8px; */
}

.card-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 250px; */
}

.card-info {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #000;
}

.card-number {
  background-color: #fef6da;
  border-radius: 8px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  margin-right: 16px;
  font-size: 18px;
  color: #efb928;
}

/* .rank-1,
.rank-2,
.rank-3,
.rank-4,
.rank-5,
.rank-6 {
  color: #efb928;
} */

.sDash_unresizable {
  resize: none;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff !important;
}

/* card nav */

.card-nav ul {
  list-style: none;
  display: flex;
  margin: 0 -0.5rem !important;
}

.card-nav ul li {
  margin: 0 0.5rem !important;
  position: relative;
}

.card-nav ul li a {
  font-weight: 500;
  color: #868eae;
  font-size: 0.75rem;
}

.card-nav ul li.active a {
  color: #f8d247;
  font-weight: 500;
}

.card-nav ul li.active:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 0.0625rem;
  background: #f8d247;
  left: 0;
  bottom: -1.1875rem;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 0.875rem;
  color: #868eae;
  margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 0.125rem;
  font-size: 0.875rem;
  font-weight: 600;
  left: 0.3125rem;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 1.375rem;
  margin: .375rem 0 0;
} */

.growth-downward h1 sub {
  color: #ff4d4f;
}

.growth-upward h1 sub {
  color: #20c997;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 2.5rem;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 1.875rem;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 0.9375rem;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress > div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 0.8125rem;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 1.25rem;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #f8d247;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
  background: #2c99ff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #ff4d4f;
}

/* color classes */

.color-primary {
  color: #f8d247;
}

.color-secondary {
  color: #ff69a5;
}

.color-info {
  color: #2c99ff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #20c997;
}

.color-danger {
  color: #ff4d4f;
}

.color-dark {
  color: #272b41;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #5a5f7d;
}

/* Button Styles */

.ant-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem !important;
  border-radius: 0.5rem !important;
}

.ant-btn-circle {
  border-radius: 50% !important;
}

.ant-btn-primary,
.btn-add_new {
  background: #f8d247 !important;
  color: #000000 !important;
}

.ant-btn-primary:hover,
.btn-add_new:hover {
  background: #e0bc3f !important; /* Un color ligeramente más oscuro para el fondo */
  color: #000;
}

.ant-btn-light {
  background-color: #d8d8d8 !important;
  color: #000000 !important;
}

.ant-btn-light:hover {
  /* background: #bdbdba !important; */
  background-color: #b0b0b0 !important;
  color: #000000 !important;
}

.ant-btn-danger {
  color: #fff !important;
}

.ant-btn-default:hover {
  color: #000 !important;
  border-color: #aaaaaa !important;
}

.ant-btn.ant-btn-light:focus {
  background: #f0f0ec;
  color: #000000;
}

.ant-btn-white {
  font-weight: 700 !important;
  font-style: normal;
  color: #585858 !important;
  background-color: transparent !important;
  /* border-color: #e3e6ef00 !important; */
}

.ant-btn-white:hover {
  background-color: white !important;
}

.button-example .ant-btn {
  margin: 0.25rem;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

/* .ant-btn-white:#FEDADA {
  color: #F8D247 !important;
  background-color: #fff !important;
  border-color: #fff;
} */

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn-link {
  background-color: #fff;
  border-color: #fff;
  color: #f8d247;
}

.ant-btn-text,
.ant-btn-text:hover {
  color: #9f9f9d !important;
  background-color: transparent !important;
}
/* .ant-btn-link:#FEDADA {
  border-color: #fff;
  background: #fff;
} */

/* .ant-btn-light,
.ant-btn-light:#FEDADA,
.ant-btn-link:#FEDADA,
.ant-btn-dashed {
  color: #5a5f7d !important;
} */

/* .ant-btn-light:hover {
  background: #f4f5f7 !important;
} */

.ant-btn-light.btn-outlined {
  background: transparent !important;
}

.ant-btn-light.btn-transparent {
  background: #f4f5f715;
}

.ant-btn-dashed {
  border-width: 0.0625rem !important;
}

.ant-btn-dashed {
  color: #f8d247;
  border: 0.0625rem dashed #f8d247;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #f8d24760;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 2.375rem;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

/* .ant-btn-white[disabled]:#FEDADA {
  color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:#FEDADA {
  color: #fff !important;
  background: #F8D24760 !important;
} */

.btn-icon {
  padding: 0 0.8125rem;
}

.btn-inc,
.btn-dec {
  height: 2.375rem;
  width: 2.375rem;
  font-size: 1.25rem;
  padding: 0 0.75rem !important;
  border-radius: 0.625rem !important;
  border: 0 none;
}

/* .btn-inc:#FEDADA,
.btn-dec:#FEDADA {
  background: #F8D24710 !important;
  border: 0 none !important;
}

.btn-inc:#FEDADA i,
.btn-inc:#FEDADA svg,
.btn-dec:#FEDADA i,
.btn-dec:#FEDADA svg {
  color: #F8D247;
} */

/* Form styles */

.heading-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.p-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #000 !important;
  text-transform: uppercase;
}
/* Label styles */
.ant-form-item-label > label {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem !important;
  color: #4e4e4e !important;
}

.ant-form-item-label > label::after {
  content: ':' !important;
  font-family: 'Poppins', sans-serif !important;
  position: relative;
  top: 0rem !important;
}

.ant-input-prefix {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 0.8125rem !important;
  color: #000000;
}

.ant-input-suffix {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 0.8125rem !important;
  /* color: #9f9f9d !important; */
  color: #000000;
}

.ant-picker-input > input::placeholder {
  color: #adb4d2 !important;
}

.ant-picker-suffix {
  color: #efb928 !important;
}

.ant-input-affix-wrapper-disabled {
  background-color: #fff !important;
}
/* span styles */

.ant-form-span {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem !important;
  color: #4e4e4e !important;
}

/* Input styles */
.ant-input {
  font-family: 'Poppins', sans-serif;
  color: #000 !important;
}

.ant-input,
.ant-upload,
.ant-input-password,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select-selector {
  border-radius: 0.5rem !important;
  /* border-color: #9F9F9D !important; */
}

.ant-input[disabled],
.ant-input-affix-wrapper-disabled {
  background-color: #f8f8f8 !important;
}
.ant-picker-focused,
.ant-picker:hover {
  border-color: #ffe573 !important;
}

/* Input Affix */

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 0.3125rem;
}

/* .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
} */

/* radio style */

.ant-radio {
  top: 0 !important;
}

.ant-radio-wrapper {
  /* line-height: 0 !important; */
  margin-right: 1.25rem !important;
  align-items: center !important;
}

.ant-radio-inner {
  width: 1.5rem;
  height: 1.5rem;
}

.ant-radio-checked .ant-radio-inner {
  border-width: 0.375rem !important;
}

.ant-radio-inner::after {
  content: none !important;
}

.ant-wrapper-center {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  height: auto;
}

/* Custom tag */

.custom-tag {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 12px 8px 20px !important;
  position: relative;
  border: none;
  color: #000 !important;
  font-family: 'Poppins', sans-serif;
  text-transform: none !important;
  font-size: 12px !important;
}

.custom-tag::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tag-green::before {
  background-color: #17c64d; /* Color verde */
  opacity: 1;
}

.tag-yellow::before {
  background-color: #f8d247; /* Color amarillo */
}

.tag-red::before {
  background-color: #f84747; /* Color rojo */
}

.tag-green {
  background-color: #e8f9ed; /* Fondo verde claro */
}

.tag-yellow {
  background-color: #fefbed; /* Fondo amarillo claro */
}

.tag-red {
  background-color: #feeded; /* Fondo rojo claro */
}

.tag-default::before {
  background-color: #d9d9d9; /* Color por defecto */
}

.tag-default {
  background-color: #fafafa; /* Fondo por defecto */
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 2rem !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 1.875rem !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 1.875rem !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fff !important;
  color: #f8d247 !important;
}

/* pagination */

.pagination-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2rem !important;
}

.ant-pagination-item-active {
  background-color: #f8d247 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 0.8125rem;
  line-height: 1.875rem !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 1.875rem;
  line-height: 1.875rem;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 1.875rem;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 0.0625rem solid #f1f2f6 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #5a5f7d !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 1.75rem !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #5a5f7d;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 1.875rem !important;
  color: #5a5f7d;
}

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination {
  margin: -0.25rem -0.75rem !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 0.25rem !important;
}

.ant-table-pagination {
  margin-top: 1.875rem !important;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}
.submission-successModal .icon-success {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  margin: 0 auto 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 0.0625rem solid #20c997;
}
.submission-successModal p {
  margin-bottom: 0;
}
.submission-successModal .icon-success svg {
  color: #20c997;
}
.submission-successModal .submission-action {
  margin: 1.875rem -0.3125rem -0.3125rem -0.3125rem;
}
.submission-successModal .submission-action button {
  margin: 0.3125rem;
  height: 2.375rem;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.625rem !important;
  line-height: 2.5rem;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2.5rem !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 1.25rem;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 0.125rem !important;
}

.ant-rate-text {
  color: #5a5f7d;
}

/* result */

.ant-result-icon {
  margin-bottom: 1.25rem !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 0.625rem;
}

.ant-result-extra {
  height: 2.125rem;
  padding: 0.25rem 0.67rem;
}

/* Styles for typography  (details) */

.ant-typography {
  font-family: 'Poppins', sans-serif;
  color: #000 !important;
}
.ant-typography strong {
  font-weight: 500;
  color: #000000 !important;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
  color: #000000 !important;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */
/* .ant-select-selection-item, */
.ant-select-item-option-content {
  font-family: 'Poppins', sans-serif !important;
}

.ant-select-selection-item,
.ant-select-arrow,
.ant-select-selection-placeholder {
  font-family: 'Poppins', sans-serif !important; /* Reemplaza 'NuevaFuente' con el nombre de la fuente que desees */
  color: #000 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.375rem !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2.0625rem !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.sDash_fullwidth-select {
  width: 100%;
}

/* Details pdf info */
/* .details-pdf-container {
  width: 100%;
  height: 46.25rem;
  position: relative;
}

.details-pdf-embed {
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  height: 100%;
} */

/* details component */
.details-image {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.details-image-w {
  max-height: 31.25rem; /* Puedes ajustar este valor según tus necesidades */
  /* display: block;
  margin: 0 auto; */
}

.details-pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden; /* Asegúrate de ocultar cualquier desbordamiento */
}

.details-pdf-embed {
  width: 100%;
  height: 37.5rem;
  max-width: 100%;
  max-height: 80vh; /* Ajustar la altura máxima según sea necesario */
  border: none;
}

@media (max-width: 48rem) {
  .details-pdf-embed {
    height: 25rem; /* Ajustar la altura para pantallas más pequeñas */
  }
}

@media (max-width: 30rem) {
  .details-pdf-embed {
    height: 18.75rem; /* Ajustar la altura para pantallas aún más pequeñas */
  }
}

/* Empty */

.ant-empty-description {
  font-family: 'Poppins', sans-serif;
  color: #000;
}

/* Select style */

.ant-select-item-empty {
  font-family: 'Poppins', sans-serif;
  color: #4e4e4e !important;
  text-align: center;
}
/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
  font-size: 0.75rem;
  padding-bottom: 0.625rem;
  margin-bottom: 1.375rem;
  border-bottom: 0.0625rem solid #e3e6ef;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.75rem;
}

.notes-month {
  text-align: center;
  font-size: 1.75rem;
}

.notes-month section {
  font-size: 1.75rem;
}

/* Breadcrumb demo */

.demo {
  margin: 1rem;
}

.demo-nav {
  height: 1.875rem;
  line-height: 1.875rem;
  margin-bottom: 1rem;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 1.875rem;
  padding: 0 0.5rem;
}

.app-list {
  margin-top: 1rem;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 0.625rem;
}

.ant-modal .ant-modal-header {
  border-radius: 0.625rem 0.625rem 0 0;
}

.ant-modal-title {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 0.3125rem !important;
}

.project-modal .projects-members {
  margin-top: -0.75rem;
}

.project-modal .ant-form-item-control-input {
  margin-top: 0.625rem;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal-footer {
  padding: 0.625rem !important;
  text-align: left !important;
}

.sDash_addTask-modal {
  max-width: 24.375rem;
}
.sDash_addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}
.sDash_addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 1rem;
  font-weight: 500;
  color: #272b41;
}
.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
  margin-bottom: 0.9375rem;
}
.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299b8;
}
.sDash_addTask-modal .ant-form-item-control-input {
  min-height: 2.25rem;
}
.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
  color: #9299b8;
}
.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
  padding: 0.375rem 1.25rem;
  border-radius: 0.3125rem;
}
.sDash_addTask-modal textarea {
  resize: none;
  min-height: 7.8125rem;
}
.sDash_addTask-modal .sDash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -0.375rem;
}
.sDash_addTask-modal .sDash-modal-actions button {
  font-size: 0.875rem;
  margin: 0.375rem;
  height: 2.375rem;
  border-radius: 0.3125rem;
}
/* slider */

.ant-slider-handle {
  margin-top: -0.375rem !important;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 0.5938rem;
  border-radius: 12px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* custom-tabs  */

.custom-tabs .ant-tabs-tab {
  font-family: 'Poppins', sans-serif;
  /* background-color: #9f9f9d; */
  color: #000;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  border: 1px solid #9f9f9d;
  transition: background-color 0.5s;
}

/* .custom-tabs .ant-tabs-tab:hover {
  background-color: #ceaf3f;
  border: 1px solid #dab942;
  color: #fff;
} */

.custom-tabs .ant-tabs-tab-active {
  background-color: #f8d247; /* Color cuando está activo */
  border: 1px solid #f8d247;
}

.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
}

.custom-tabs .ant-tabs-ink-bar {
  display: none; /* Oculta la barra de tinta */
}

/* list */
.ant-list {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}
.ant-list-bordered {
  border-color: #e3e6ef !important;
  border-radius: 12px !important;
}

.ant-list-item-meta {
  padding: 0.625rem 1.5rem;
  border-bottom: 0.0625rem solid #f1f2f6;
}

.ant-list-items {
  padding-top: 0px !important;
}

.ant-list-items li:not(:last-child) {
  margin-bottom: 0px !important;
}

/* divider */
.ant-divider-inner-text {
  font-family: 'Poppins', sans-serif;
}
/* popover */

.ant-popover {
  position: fixed;
  z-index: 99999;
}

.ant-popover-inner {
  padding: 0.9375rem;
  box-shadow: 0 0.3125rem 1.25rem #9299b820;
  border-radius: 10px;
  text-align: center;
  /* border-radius: 0 0 0.375rem 0.375rem; */
}

.ant-popover-inner .ant-popover-title {
  padding: 0.3125rem 0.625rem 0.625rem;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 1.125rem 0 !important;
  box-shadow: 0 0.3125rem 1.25rem #9299b820 !important;
  border-radius: 0 0 0.375rem 0.375rem !important;
}

.ant-select-item {
  min-height: 1.25rem !important;
  padding: 0.25rem 0.75rem !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 1.5625rem !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background: #f8d24705;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  padding-left: 0.625rem;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #f8d247;
  background: #f8d24706;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: #f8d247;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #5a5f7d;
}

/* mail props dropdown */

.mail-props {
  padding: 0.3125rem 1.5625rem;
  border: 0 none;
  background: #fff;
  /* box-shadow: 0 .3125rem 2.5rem rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 0.75rem;
}

.mail-props li span:first-child {
  margin-right: 3.125rem;
  min-width: 2.5rem;
}

.mail-props li span:last-child {
  color: #5a5f7d;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 0.3125rem 1.875rem #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 8.75rem !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 13.75rem !important;
  box-shadow: 0 1.0625rem 1.25rem #9299b820;
}
.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 0.625rem 1.5rem;
}

.ant-dropdown-menu {
  min-width: 12.5rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #5a5f7d;
  padding: 0.3125rem 1.5625rem;
}

/* .ant-dropdown-menu li:#FEDADA {
  background-color: #F8D24705;
} */

.atbd-top-dropdwon .atbd-top-dropdwon__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  min-height: 3.125rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.atbd-top-dropdwon {
  width: 21.25rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
  height: 16.25rem;
  overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
  padding: 0 0.625rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
  padding: 0.8125rem 0.625rem;
  position: relative;
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
  margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
  position: relative;
  width: calc(100% + 1.875rem);
  left: -0.9375rem;
  right: -0.9375rem;
  height: calc(100% + 0.9375rem);
  bottom: -0.9375rem;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #f8d247;
  padding: 0.9375rem 0;
  border-radius: 0 0 0.375rem 0.375rem;
  background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -0.9375rem 1.25rem #9299b808;
  z-index: 1;
  content: '';
}

/* .atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:#FEDADA:after {
  box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:#FEDADA {
  background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:#FEDADA:after {
  opacity: 1;
  visibility: visible;
} */

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:after {
  position: absolute;
  left: -0.9375rem;
  right: -0.9375rem;
  top: 0;
  width: calc(100% + 1.875rem);
  height: 100%;
  box-shadow: 0 0.9375rem 3.125rem #9299b820;
  z-index: 1;
  content: '';
  opacity: 0;
  visibility: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
  width: 2.45rem;
  height: 2rem;
  margin-right: 0.9375rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-primary {
  background: #f8d24715;
  color: #f8d247;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-secondary {
  background: #ff69a515;
  color: #ff69a5;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
  width: 1.125rem;
  height: 1.125rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #5a5f7d;
  margin-bottom: 0.25rem;
}

.atbd-top-dropdwon .notification-text h1 span {
  color: #f8d247;
  font-weight: 500;
  padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
  font-size: 0.75rem;
  color: #adb4d2;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
  max-width: 2.5rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
  margin: -0.25rem 0.9375rem 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
  font-size: 0.5rem;
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  line-height: 0.375rem;
  border-radius: 0.5rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p.ant-scroll-number-only-unit.current {
  height: 0.9375rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.125rem;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
  font-size: 0.75rem;
  font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 0.875rem;
  font-weight: 400;
}
.add-file-dropdown a svg {
  width: 0.875rem;
}

.folder-dropdown {
  min-width: 12.5rem !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 2.5rem;
  padding: 0.375rem 1.25rem;
  font-size: 0.875rem;
  color: #5a5f7d;
  background-color: #fff;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  border: 0.0625rem solid #f1f2f6;
  box-shadow: 0 0.5rem 0.9375rem #9299b815;
}

/* .ant-tooltip .ant-tooltip-arrow {
  position: relative;
} */

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 0.75rem;
  transform: translateX(-50%);
  width: 0.8125rem;
  height: 0.8125rem;
  background: #fff;
  content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 0.625rem;
  height: 0.625rem;
  background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 0.0625rem solid #f1f2f6;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -0.2544rem;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #20c997;
}

.badge {
  font-size: 0.6875rem;
  font-weight: 500;
  padding: 0 0.4063rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
}

.badge.badge-primary {
  background: #f8d24710;
  color: #f8d247;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 0.875rem;
}

/* Select Tree */

/* .ant-select-tree-list .ant-select-tree-node-content-wrapper:#FEDADA {
  background-color: #bae7ff !important;
} */

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 0.625rem;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 0.1875rem;
  background: #f4f5f7;
  color: #5a5f7d;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.ant-switch-small:after {
  width: 0.625rem !important;
  height: 0.625rem !important;
}

/* Time Picker */

.ant-picker {
  min-width: 15.625rem;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 0.875rem;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 5.625rem;
  padding: 0rem 2.2188rem;
  height: 2.75rem;
}

.pop-confirm-top {
  margin-bottom: 0.625rem;
}

.pop-confirm-bottom {
  margin-top: 0.625rem;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 0.625rem;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 0.625rem;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e3e6ef !important;
  border-radius: 0.3125rem !important;
  background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 0.5rem;
}

.ant-upload .anticon svg {
  color: #9299b8;
}

.ant-upload-list {
  margin-top: 0.625rem !important;
}

/* upload customize ================ */

.upload-field {
  /* width: 100% !important;  */
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.custom-upload-button {
  display: flex;
  align-items: center;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.5rem;
  overflow: hidden;
  /* margin-bottom: 0.5rem; */
  /* width: 100% !important;  */
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8d247;
  color: #000;
  padding: 0 0.9375rem;
  height: 2.8125rem;
}

.upload-icon .anticon svg {
  color: #000;
  font-size: 16px;
}

.upload-text-button {
  border: none;
  /* height: 0.5rem !important; */
  /* line-height: 2.375rem; Ajusta la altura del texto */
  padding: 0 1.25rem;
  flex: 1;
  text-align: left;
  color: #000 !important;
}

.custom-upload-button:hover,
.custom-upload-button:focus {
  background-color: transparent;
  border: 0.0625rem solid #f8d247;
  color: #000 !important;
}

.upload-text-button:focus,
.upload-text-button:hover {
  /* background-color: transparent; */
  color: #000 !important;
}

.ant-upload-list {
  width: 100%;
}
/* ================ */

.sDash_upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 0.0625rem solid #e3e6ef;
  border-radius: 0.25rem;
}
.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sDash_upload-basic .sDash_upload-text {
  font-size: 0.875rem;
  color: #9299b8;
  padding-left: 0.9375rem;
}
html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
  padding-left: 0rem;
  padding-right: 0.9375rem;
}
.sDash_upload-basic .sDash_upload-browse {
  font-size: 0.875rem;
  color: #9299b8;
  display: inline-block;
  padding: 0.875rem 1.4375rem;
  border-left: 0.0625rem solid #e3e6ef;
}
html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
  border-left: 0 none;
  border-right: 0.0625rem solid #e3e6ef;
}
.ant-card.sDash_upload-form .ant-card-body {
  padding-bottom: 0.9375rem !important;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 0.3125rem 1.25rem #9299b820;
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.9375rem 0 !important;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  font-weight: 400;
  color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 0.5rem;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 0.3125rem;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 0.75rem 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* .table-bordered .ant-table-tbody > tr.ant-table-row:#FEDADA > td {
  background: #f8f9fb !important;
} */

/* Table styles */

.ant-table-thead {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 0.875rem;
}

.ant-table-thead > tr > th {
  background-color: #efefef !important;
  color: #000 !important;
  /* border-bottom-right-radius: .5rem;  */
}
.ant-table-thead > tr:last-child > th:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem;
}
.ant-table-thead > tr:last-child > th:last-child {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem;
}

.ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid #dadada; /* Ajusta el grosor (.125rem), el estilo (solid) y el color (#000) según sea necesario */
}

.ant-table-tbody {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 0.8125rem;
}

.ant-table-tbody > tr > td {
  background-color: #fff !important;
  color: #000 !important;
  padding-block: 24px !important;
}

/* .table-responsive .ant-table-cell {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
} */

/* 
.ant-table-cell {
  min-width: 130px !important;
  max-width: 800px !important;
  white-space: pre-wrap !important; Preserva los saltos de línea y ajusta el texto
  word-wrap: break-word !important;  Ajusta las palabras largas
  overflow-wrap: break-word !important;  Asegura que las palabras largas se dividan
  text-align: justify;
} */

.ant-table-comments {
  /* min-width: 130px !important;
  max-width: 800px !important; */
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  text-align: justify;
}

.ant-deleted-row {
  text-decoration: line-through;
  color: #f84747; /* Puedes ajustar el color según tus necesidades */
}

/* Table Bordered*/

.table-bordered .ant-table-thead > tr > th {
  background: #fff;
  border-top: 0.0625rem solid #f1f2f6;
}

.table-bordered .ant-table-tbody > tr > td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 1rem 1.5625rem;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
  padding: 0 !important;
}

.full-width-table .ant-table {
  border-radius: 0.625rem !important;
}

.full-width-table .top-seller-table {
  min-height: 25.375rem;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
  white-space: normal;
  padding: 1rem 0.9375rem;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:first-child {
  padding-left: 1.5625rem;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:last-child {
  padding-right: 1.5625rem;
}

.full-width-table .top-seller-table th {
  text-align: right;
  color: #272b41;
  font-weight: 500;
}

.full-width-table .top-seller-table td {
  color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody > tr > td {
  text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
  text-align: left !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
  border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
  margin: 0;
  border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  padding: 0;
  border: 0 none;
  background: #fff;
  margin-right: 0.5rem;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
  border-right-width: 0rem;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
  border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
  min-width: 13.125rem;
}

.atbd-chatbox__messageControl ul li a {
  padding: 0.25rem 1.5rem;
}

.atbd-chatbox__emoji {
  margin: -0.28rem 0;
  padding: 0 0.625rem;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 1.25rem;
  padding: 0.25rem 0.4375rem;
  background: #fff;
}

/* .atbd-chatbox__emoji ul li a:#FEDADA {
  background-color: transparent;
} */

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-space {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 0.5rem 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}
.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
}
.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #5a5f7d;
}

/* page heading */

.ant-page-header {
  padding: 1.625rem 1.875rem 1.625rem 1.875rem !important;
}

.ant-page-header.header-boxed {
  padding: 1.625rem 11.25rem 1.625rem 11.25rem !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.25rem !important;
  position: relative;
  padding-left: 12.8px;
  /* padding-right:.625rem; */
}

.ant-page-header-heading-title::before {
  content: '';
  position: absolute;
  left: -2.5rem;
  top: 48%;
  transform: translateY(-50%);
  width: 46.4px;
  height: 60%;
  background-color: #f8d247;
  vertical-align: middle;
}

.page-header-span {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #585858 !important;
  display: inline-block;
  margin-right: 0.75rem !important;
}

/* .page-header-select-filter {
  margin-left: .75rem !important;
} */

.page-header-select-filter .ant-select-selector {
  min-width: 15.625rem !important;
}
.page-header-select-filter > .ant-select-selector {
  padding-top: 0.25rem !important;
}

.ant-dropdown-menu-title-content > .page-header-select-filter {
  width: 100%;
}

.ant-dropdown-menu-title-content > .page-header-select-filter > .ant-select-selector {
  padding-top: 0.4375rem !important;
  height: 3rem !important;
}

@media only screen and (max-width: 600px) {
  .ant-page-header-heading-extra > .ant-row {
    flex-direction: column !important;
  }
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: .0625rem solid #ccc; */
  box-shadow: 0 0.9375rem 3.125rem #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 0.9375rem 3.125rem #9299b820;
}

.row-dragging td {
  padding: 1rem;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20c997;
  border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 0.625rem;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
  width: 24.375rem !important;
}
.sDash_export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 1.125rem 1.875rem 0 1.875rem;
}
.sDash_export-wrap .ant-modal .ant-modal-body {
  padding: 1.5625rem 1.875rem 1.875rem 1.875rem;
}
.sDash_export-wrap .ant-modal .sDash-button-grp {
  text-align: right;
}
.sDash_export-wrap .ant-modal .sDash-button-grp {
  margin: 1.25rem -0.5rem -0.5rem -0.5rem;
}
.sDash_export-wrap .ant-modal .sDash-button-grp button {
  font-size: 0.875rem;
  font-weight: 500;
  text-align: right;
  height: 2.5rem;
  padding: 0 1rem;
  margin: 0.5rem;
}
.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
  color: #9299b8 !important;
}
.sDash_export-wrap .ant-form-item-control-input {
  min-height: 2.25rem;
}

.sDash_export-wrap .ant-form-item-control-input input {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.375rem 1.25rem;
  border-radius: 0.3125rem;
  color: #9299b8;
}

.sDash_export-wrap .ant-select-single {
  width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
  margin-bottom: 0.9375rem;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
  padding: 0 1.25rem;
  border-color: #e3e6ef !important;
}

.sDash_create-file .sDash-button-grp {
  text-align: right;
}

.sDash_create-file .ant-modal {
  width: 24.375rem !important;
}
.sDash_create-file .ant-modal .ant-form-item {
  margin-bottom: 1.25rem;
}
.sDash_create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0.375rem;
}
.sDash_create-file .sDash-button-grp button {
  height: 2.5rem;
  border-radius: 0.3125rem;
  margin: 0.3125rem;
}

.sDash_create-file .ant-form-item-control-input {
  border-radius: 0.3125rem;
}

.sDash_create-file .ant-form-item-control-input input {
  border: 0.0625rem solid #e3e6ef;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
  top: 0.625rem;
}
.sDash_task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 1.875rem 1.875rem 0;
}
.sDash_task-details .ant-modal {
  width: 37.5rem !important;
}
.sDash_task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 0.25rem;
}
.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #868eae;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
  font-size: 1rem;
  display: block;
  margin-bottom: 0.5rem;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea {
  padding: 0.625rem 1.25rem;
  min-height: 5.5rem;
  width: 100%;
  border: 0 none;
  border-radius: 0.25rem;
  background-color: #f4f5f7;
  resize: none;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea:focus {
  outline: none;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea::placeholder {
  color: #5a5f7d;
  font-size: 0.9375rem;
}
.sDash_task-details .ant-modal-body {
  padding: 0.875rem 1.875rem 1.875rem 1.875rem;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block {
  margin-top: 1.625rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
  position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  position: absolute;
  width: 15rem;
  padding: 1.125rem;
  left: 0;
  top: 3.125rem;
  box-shadow: 0 0.9375rem 1.875rem #9299bb30;
  background-color: #fff;
  border: 0.0625rem solid #e3e6ef;
  border-radius: 0.375rem;
  z-index: 222;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .add-checklist {
  padding: 0.625rem;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
  height: 2.375rem;
  border: 0.0625rem solid #e3e6ef;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 0.9375rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 0.1875rem;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299bb;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-right: 0.9375rem;
}
html[dir='rtl']
  .sDash_task-details
  .ant-modal-body
  .sDash_checklist-block
  .addChecklist-form
  .addChecklist-form-action
  .btn-add {
  margin-left: 0.9375rem;
  margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
  height: 2.375rem;
  padding: 0rem 1.1481rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
  margin-top: 1.625rem;
}
.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top button {
  padding: 0rem 1.02rem;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__title {
  font-size: 1rem;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 0;
}

.sDash_task-details
  .ant-modal-body
  .sDash_checklist-row
  .sDash_checklist__progress
  .ant-progress-inner
  .ant-progress-bg {
  height: 0.3125rem !important;
  background-color: #20c997;
}
.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  font-size: 0.75rem;
  font-weight: 500;
  order: -1;
  margin: 0 0.625rem 0 0;
}
html[dir='rtl']
  .sDash_task-details
  .ant-modal-body
  .sDash_checklist-row
  .sDash_checklist__progress
  .ant-progress
  .ant-progress-text {
  margin: 0 0 0 0.625rem;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
  margin: 0.3125rem 0 0.3125rem 0;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 1.125rem;
  height: 1.125rem;
  border-color: #c6d0dc;
}
.sDash_task-details
  .ant-modal-body
  .sDash_checklist-tasks
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #20c997;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #20c997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-item .sDash_checklist-tasks-wrap button {
  padding: 0rem 2.1875rem;
}

.sDash_task-details .ant-modal-body {
  max-height: 50rem;
  overflow-y: auto;
}

/* Checkbox */
/* container checkbox prices */

.checkbox-estate-container {
  max-height: 145px;
  overflow-y: auto;
  border: 1px solid #F8D247;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}
.ant-checkbox {
  font-family: 'Poppins', sans-serif;
}

.ant-checkbox-inner {
  border-radius: 4px !important;
  transition: all 0.3s;
}

.ant-checkbox-inner:hover {
  border-radius: 4px !important;
}

/* switch */
.ant-form-item-control-input button {
  font-family: 'Poppins', sans-serif;
  height: 18px !important;
}

/* .ant-checkbox-wrapper {
  font-family: 'Poppins', sans-serif;
} */

@media (max-width: 47.9375rem) {
  .ant-page-header {
    padding: 1.625rem 0.9375rem 1.625rem 0.9375rem !important;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -0.375rem -0.25rem;
}

.page-header-actions button {
  font-size: 0.75rem;
  font-weight: 500;
  height: 2.125rem;
  padding: 0 0.77rem;
  box-shadow: 0 0.1875rem 0.3125rem #9299b805;
  margin: 0.375rem 0.25rem;
}

/* .page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
} */

.page-header-actions button + button {
  margin-left: 0.5rem;
}

.page-header-actions button.ant-btn-white svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.125rem;
  color: #f8d247;
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}
/* 
.ant-menu-dark .ant-menu-item a:#FEDADA {
  color: #ffffff;
} */

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* background-color: rgba(255, 255, 255, 0.05); */
  background-color: rgb(252, 244, 217);
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label dashboard */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.375rem;
  color: #5a5f7d;
}

.chart-label .label-dot {
  margin-right: 0.5rem;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
}
.chart-label .chart-text {
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 13px;
  color: #000;
}

.chart-label .ant-badge {
  margin-right: 0.5rem;
}

.chart-label .label-dot.dot-success {
  background: #20c997;
}

.chart-label .label-dot.dot-info {
  background: #f8d247;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}
/* 
.chart-label .label-dot.dot-success {
  background: #20c997;
}

.chart-label .label-dot.dot-info {
  background: #f8d247;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
} */

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 0.625rem !important;
}

/* Overlay Dark */
.overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.overlay-dark.show {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

/* Responsive CSS */

@media only screen and (max-width: 99.9375rem) {
  .ant-page-header.header-boxed {
    padding: 1.625rem 8.125rem 1.625rem 8.125rem !important;
  }
}
@media only screen and (max-width: 93.75rem) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }
  .pop-confirm .ant-btn {
    padding: 0 1.25rem;
    max-width: 3.75rem;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 18.75rem !important;
  }
  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 5rem !important;
  }
}

@media only screen and (max-width: 87.4375rem) {
  .ant-page-header.header-boxed {
    padding: 1.625rem 3.125rem 1.625rem 3.125rem !important;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .ant-page-header {
    padding: 0 0.9375rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .ant-page-header.header-boxed {
    padding: 1.625rem 1.875rem 1.625rem 1.875rem !important;
  }
  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 1.875rem;
  }
  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 18.75rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 1.25rem;
  }
  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 50rem) {
  .ant-page-header-heading-left {
    max-width: 20rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .ant-page-header {
    padding: 1.625rem 0.9375rem 1.625rem 0.9375rem !important;
  }
  .ant-page-header-heading {
    flex-flow: column;
    align-items: center;
    justify-content: center !important;
  }
  .ant-page-header-heading-left {
    max-width: 100%;
  }
  .ant-page-header-heading .ant-page-header-heading-title {
    margin-right: 0;
    white-space: normal;
    text-align: center;
  }
  .ant-page-header-heading-extra {
    white-space: normal !important;
    margin: 0.75rem 0 0.25rem !important;
  }
  .ant-page-header-heading-extra .page-header-actions {
    white-space: normal !important;
    text-align: center;
  }
  .ant-card-body {
    padding: 1.25rem !important;
  }
  .ant-card-head {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 0.3125rem;
  }
  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    height: 1.5625rem;
    min-width: 1.5625rem;
    line-height: 1.375rem;
  }
  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }
  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }
  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 0.5rem;
  }
  .ant-table-pagination li {
    margin-right: 0.5rem !important;
  }
  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 1.5625rem !important;
  }
  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 1.4375rem !important;
  }
  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .btn-inc,
  .btn-dec {
    height: 1.875rem;
    width: 1.875rem;
  }
  .ant-page-header {
    padding: 1rem 1.25rem !important;
  }
  .ant-layout-header {
    height: auto;
  }
  .ant-card-head {
    line-height: 1;
  }
  .ant-card-head-title {
    white-space: normal !important;
  }
  .pop-confirm .ant-btn {
    max-width: 3.75rem;
    padding: 0 1.25rem;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 20rem !important;
  }
  .pop-confirm.pop-confirm-bottom {
    margin-left: 5.9375rem !important;
  }
  /* Card Heading */
  .ant-card-head-wrapper {
    flex-flow: column;
    align-items: center;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: 0.625rem 0 1rem !important;
    float: none;
    margin: 0;
    flex-flow: column;
  }
  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger + .card-nav {
    margin-bottom: 0.5rem;
  }
  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
    margin: 0 !important;
  }
  .ant-card-head-wrapper .ant-card-head-title {
    padding: 1.25rem 0 0rem !important;
  }
  .card-nav ul li.active:before {
    display: none;
  }
  .ant-card-head-title > div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .ant-card-head-title > div span {
    margin: 0.5rem 0 0;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 0.9375rem;
}

/* Empty */

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.data-not-found {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem !important;
  color: #000;
}

.ant-empty .ant-empty-footer button {
  padding: 0rem 0.9738rem !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 1.25rem 1.5625rem;
}
.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #5a5f7d;
}
.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #272b41;
}

.addEvent-modal .ant-modal-body {
  padding: 1.4375rem 1.5625rem;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 28.125rem;
  padding: 0 !important;
  margin: 0.375rem 0 0 !important;
  box-shadow: 0 0.625rem 2.5rem #9299b820 !important;
}

.event-dropdown div {
  border-radius: 0.5rem;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 29.9375rem) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -0.3125rem -0.625rem 0 -0.3125rem;
  }
  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }
  .pop-confirm .ant-btn {
    display: block;
    padding: 0 1.25rem;
    max-width: 5rem;
    min-width: 5rem;
    margin: 0.625rem;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 0rem !important;
  }
  .pop-confirm.pop-confirm-top {
    margin-left: 0rem !important;
  }
  .pop-confirm.pop-confirm-bottom {
    margin-left: 0rem !important;
    margin-top: 0rem !important;
  }
}

@media only screen and (max-width: 25rem) {
  .ant-select {
    width: 100% !important;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }
  .atbd-top-dropdwon {
    width: 17.5rem;
    min-width: 11.25rem;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__title {
    min-height: 2.5rem;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
    margin-bottom: 0.625rem;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 0.625rem 0rem;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content img {
    margin-right: 0.9375rem;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin-left: 0;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .atbd-top-dropdwonText {
    min-width: 9.6875rem;
  }
  .ant-drawer-content-wrapper {
    width: 16.25rem !important;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 23.6875rem) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 2rem !important;
    line-height: 1.875rem !important;
  }
  .ant-notification-notice {
    width: 17.1875rem;
  }
}

@media (max-width: 61.9375rem) {
  .mail-sideabr {
    box-shadow: 0 0 0.625rem #00000020;
  }
}

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }
  .customizer-trigger {
    display: none;
  }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
  position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
  height: 12.5rem;
}

.ant-card-body {
  position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 12.5rem;
}

.starActive svg {
  color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 1.6875rem;
  height: 1.6875rem;
  background: none;
  color: #5a5f7d;
  border: 0.0625rem solid #f1f2f6;
  padding: 0;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0;
  top: auto;
  background-color: #fff;
}

.jvectormap-zoomin {
  border-radius: 0.375rem 0.375rem 0 0;
  bottom: 2.25rem;
}

.jvectormap-zoomout {
  border-radius: 0 0 0.375rem 0.375rem;
  bottom: 0.625rem;
}

.jvectormap-tip {
  padding: 0.4375rem 0.75rem;
  border: 0 none;
  font-size: 0.75rem;
  background: #272b41;
}

.btn-rtl {
  width: 3.75rem;
  height: 3.75rem;
  border: 0.0625rem solid #fff;
  background: #4347d9;
  color: #fff;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -1.875rem;
  z-index: 99999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -0.625rem;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 0.625rem;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 1.875rem;
}

.common-ul {
  padding: 0.3125rem 0;
  padding-left: 0.625rem;
}

.display {
  display: none;
}

li.active > .display {
  display: block;
}

/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
  margin-right: 0.9375rem;
}
html[dir='rtl'] span[aria-label='arrow-right'] svg,
html[dir='rtl'] span[aria-label='arrow-left'] svg {
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
  text-align: right !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-placement-rightTop {
  left: -10.625rem !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
  text-align: right;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
  right: auto;
  left: 1rem;
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 0.9375rem !important;
}

/* Auth0 style */
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: 11.25rem !important;
  margin: 0 !important;
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-name {
  font-size: 1.125rem !important;
}

.auth0-lock.auth0-lock .auth0-lock-close-button,
.auth0-lock.auth0-lock .auth0-lock-back-button {
  box-shadow: none !important;
  padding: 0.125rem !important;
}
.common-ul {
  padding: 0.3125rem 0;
  padding-left: 0.625rem;
}

.display {
  display: none;
}

li.active > .display {
  display: block;
}

/* sidebar styles */

.ant-menu-title-content {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 0.8125rem !important;
  color: #000 !important;
  /* padding-bottom: 16px !important; */
}

/* .ant-menu-item-selected a {
  color: #000 !important;
} */

.ant-menu-item-selected {
  background-color: #fcf4d9 !important;
  border-radius: 0.5rem !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
  filter: brightness(0) saturate(100%) invert(93%) sepia(10%) saturate(7462%) hue-rotate(338deg) brightness(99%)
    contrast(90%);
}

.ant-menu-item-selected a {
  font-weight: 500;
  font-style: normal;
}

.ant-menu-item:hover,
ant-menu-item:focus {
  background-color: #fcf4d9 !important;
  border-radius: 0.5rem !important;
}

/* .ant-menu-title-content{
  color: #000 !important;
  font-size: .75rem !important;
} */

.ant-menu-title-content a {
  color: #000 !important;
}

/* Table styles */

.table-actions {
  display: flex;
}

.table-actions button {
  width: auto;
  height: auto;
  padding: 0;
  background-color: transparent;
  color: #9f9f9d;
}

.table-actions button:hover,
.table-actions button:focus {
  background-color: transparent;
  color: #6b6b6b;
}

.wide-dropdown .action {
  /* width: auto;
  height: auto;
  padding: 0;
  background-color: transparent;
  color: #9f9f9d; */
  display: block;
  color: rgb(136, 136, 136);
  padding: 6px 12px;
  cursor: pointer;
  transition: color 0.3s;
}

.wide-dropdown .action:hover,
.wide-dropdown .action:focus {
  /* background-color: transparent;
  color: #6b6b6b; */
  background: rgba(248, 210, 71, 0.063);
  color: rgb(248, 210, 71);
}

/* Styles for tickets */

.more {
  padding: 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.more:hover,
.more:focus {
  background: rgba(248, 210, 71, 0.063);
  color: rgb(248, 210, 71);
}

.description-container {
  height: 7rem;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  margin-bottom: 0.5rem;
}

.cursor {
  cursor: pointer;
}
/* .table-actions button.ant-btn-primary:hover {
  color: #ADB4D2;
} */

/* Transitions */

/* .fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2s;
} */

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.fade-enter-active {
  animation: fade-in 1.2s;
}

.fade-exit-active {
  animation: fade-out 1.3s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-put {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
